@font-face {
    font-family: "Poppins Regular";
    src: url("../../resources/fonts/Poppins-Regular.ttf");
}

#btn-mobile {
    text-align: left;
}

#list-citas {
    list-style: none;
    text-align: left;
    & li {
        margin-bottom: 15px;
    }
}

.padding-li {
    padding: 5px;
}

.container-opciones-salas {
    background: var(--unnamed-color-00003a) 0% 0% no-repeat padding-box;
    background: #00003A 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 200px;
    margin-top: 0px;
    color: #fff;
    position: absolute;
    right: 120px;

    & div {
        background-color: #5F6FBE !important;
        width: 100%;
        height: 1px;
        margin: 5px 0;
    }
    & p{
        margin-left: 10px;
        cursor: pointer;
    }
}