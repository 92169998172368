#pilares {
	
	& h1 {
		    color: #51647D;
    font-family: "Montserrat Bold";
	}
	& .pilarNutricion {
		// overflow: hidden;
		// background-image: url(../../../resources/images/pilares/cuadro-pilar-nutricion-completo.svg);
		// background-repeat: no-repeat;
		// background-position: center;
		margin-bottom: 80px;
		// width: 100%;
		// background-size: contain;
		// padding: 12% 0%;
		// position: relative;
		// display: flex;
		// justify-content: center;
		// align-items: center;
		& .text-mobile {
			color: #fff;
		}
	}
	& .nombre-speaker-nutricion {
		font-family: 'Lato Bold';
		background-color: #328473;
		color: #fff;
		float: left;
		padding: 10px;
	}
}

@media only screen and (max-width: 600px) {
	#pilares {
		& .pilarNutricion {
			background-image: url(../../../resources/images/pilares/cuadro-pilar-nutricion.svg);
			margin-left: 0%;
			background-size: cover;

			& .icono-pilar {
				width: 50%;
			}
		}
	}
}

@media only screen and (min-width: 600px) {
	#pilares {
		& .pilarNutricion {
			background-image: url(../../../resources/images/pilares/cuadro-pilar-nutricion.svg);
			margin-left: 0%;
			background-size: cover;
			& .icono-pilar {
				width: 50%;
			}
		}
	}
}

@media only screen and (min-width: 768px) {
	#pilares {
		& .pilarNutricion {
			background-image: url(../../../resources/images/pilares/cuadro-pilar-nutricion.svg);
			// height: 450px;
			// margin-left: 5%;
			& .icono-pilar {
				width: 30%;
			}
		}
	}
}

@media only screen and (min-width: 992px) {
	#pilares {
		& .banner {
			display: block;
		}
		& .pilarNutricion {
			background-image: none;

			& .icono-pilar, & .text-mobile {
				display: none;
			}
		}
	}
}

@media only screen and (min-width: 1200px) {
}
