@import '../../resources/scss/variables';

@font-face {
	font-family: 'Montserrat Bold';
	src: url('../../resources/fonts/Montserrat-Bold.ttf');
}

@font-face {
	font-family: 'Montserrat Regular';
	src: url('../../resources/fonts/Montserrat-Regular.ttf');
}

#speakers {
	font-family: 'Montserrat Regular';

	& h1 {
		color: #51647d;
		font-family: 'Montserrat Bold';
	}

	& .logos {
		width: 150px;
		height: 120px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		margin: auto;
	}

	& .section {
		background-image: url(../../resources/images/home/cuadro-azul-completo.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		margin-bottom: 30px;
		// width: 70%;

		& h2 {
			color: #fff;
			font-family: 'Montserrat Bold';
			padding-left: 5%;
		}

		& p {
			color: #fff;
			padding-left: 5%;
		}
	}

	& p {
		color: $color-azul;
	}

	& .contenedor-speaker {
		text-align: center;

		& img {
			width: 70%;
		}

		& .nombre {
			font-family: 'Montserrat Bold';
			font-size: 32px;
			margin: 10px 0;
		}

		& .separador {
			width: 100%;
			height: 2px;
			background-color: #51647d;
		}
		& .cv {
			margin: 10px 0;
			color: #51647d;
		}
	}
	& .logo-imta {
		width: 38%;
	}
}

@media only screen and (max-width: 600px) {
	#speakers {
		& .img-pilar {
			width: 100%;
		}
		& h1 {
			font-size: 28px;
		}
		& .section {
			background-image: url(../../resources/images/home/cuadro-azul.svg);
			margin-left: 0%;
			background-size: cover;
		}
	}
}

@media only screen and (min-width: 600px) {
	#speakers {
		& .section {
			background-image: url(../../resources/images/home/cuadro-azul.svg);
			margin-left: 0%;
			background-size: cover;
		}
	}
}

@media only screen and (min-width: 768px) {
	#speakers {
		& .img-pilar {
		}
	}
}

@media only screen and (min-width: 992px) {
	#speakers {
		& .img-pilar {
		}
	}
}

@media only screen and (min-width: 1200px) {
	#speakers {
	}
}
