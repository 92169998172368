@font-face {
	font-family: 'Lato Bold';
	src: url('../../../resources/fonts/Lato-Bold.ttf');
}

#pilares {
	& h1 {
		color: #51647d;
		font-family: 'Montserrat Bold';
	}
	& .pilarEmocional {
		overflow: hidden;
		// background-image: url(../../../resources/images/pilares/cuadro-pilar-emocional-completo.svg);
		// background-repeat: no-repeat;
		// background-position: center;
		margin-bottom: 80px;
		// width: 100%;
		// background-size: contain;
		// padding: 12% 0%;
		// position: relative;
		// display: flex;
		// justify-content: center;
		// align-items: center;

		& .text-mobile {
			color: #fff;
		}
	}
	& .nombre-speaker-emocional {
		font-family: 'Lato Bold';
		background-color: #7ea3c9;
		color: #fff;
		float: left;
		padding: 10px;
	}
}

@media only screen and (max-width: 600px) {
	#pilares {
		& .banner {
			display: none;
		}
		& .pilarEmocional {
			background-image: url(../../../resources/images/pilares/cuadro-pilar-emocional.svg);
			margin-left: 0%;
			background-size: cover;

			& .icono-pilar {
				width: 50%;
			}
		}
	}
}

@media only screen and (min-width: 600px) {
	#pilares {
		& .banner {
			display: none;
		}
		& .pilarEmocional {
			background-image: url(../../../resources/images/pilares/cuadro-pilar-emocional.svg);
			margin-left: 0%;
			background-size: cover;

			& .icono-pilar {
				width: 50%;
			}
		}
	}
}

@media only screen and (min-width: 768px) {
	#pilares {
		& .pilarEmocional {
			// background-image: url(../../../resources/images/pilares/cuadro-pilar-emocional.svg);
			// height: 450px;
			// margin-left: 5%;
			& .icono-pilar {
				width: 30%;
			}
		}
	}
}

@media only screen and (min-width: 992px) {
	#pilares {
		& .banner {
			display: block;
		}
		& .pilarEmocional {
			background-image: none;

			& .icono-pilar,
			& .text-mobile {
				display: none;
			}
		}
	}
}

@media only screen and (min-width: 1200px) {
}
