@import '../../resources/scss/variables';

@font-face {
	font-family: 'Lato Regular';
	src: url('../../resources/fonts/Lato-Regular.ttf');
}

@font-face {
	font-family: 'Montserrat Regular';
	src: url('../../resources/fonts/Montserrat-Regular.ttf');
}

@font-face {
	font-family: 'Montserrat Light';
	src: url('../../resources/fonts/Montserrat-Light.ttf');
}

@font-face {
	font-family: 'Montserrat Bold';
	src: url('../../resources/fonts/Montserrat-Bold.ttf');
}

#home {
	overflow: hidden;
	margin-bottom: 40px;
	padding-top: 40px;

	& h1 {
		font-size: 36px;
		color: $color-azul;
		font-family: 'Montserrat Bold';
	}
	& .section {
		background-image: url(../../resources/images/home/cuadro-azul-completo.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		margin-bottom: 30px;
		margin-left: 5%;
		// width: 70%;

		& h2 {
			color: #fff;
			font-family: 'Montserrat Bold';
			padding-left: 5%;
		}

		& p {
			color: #fff;
			padding-left: 5%;
		}
	}

	& .descrip-pilar {
		color: #000;
	}
}

@media only screen and (max-width: 600px) {
	#home {
		& h1 {
			font-size: 28px;
		}
		& .section {
			background-image: url(../../resources/images/home/cuadro-azul.svg);
			margin-left: 0%;
		}
	}
}

@media only screen and (min-width: 600px) {
	#home {
		& h1 {
			font-size: 28px;
		}
		& .section {
			background-image: url(../../resources/images/home/cuadro-azul.svg);
			margin-left: 0%;
		}
	}
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
	#home {
		& .section {
			background-image: url(../../resources/images/home/cuadro-azul-completo.svg);
			margin-left: 0%;
		}
	}
}

@media only screen and (min-width: 1200px) {
	#home {
		& h1 {
			font-size: 36px;
		}
		& .section {
			background-image: url(../../resources/images/home/cuadro-azul-completo.svg);
		}
	}
}
