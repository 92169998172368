@import "../../resources/scss/variables";

@font-face {
  font-family: "Montserrat Regular";
  src: url("../../resources/fonts/Montserrat-Regular.ttf");
}

#administration {
  font-family: "Montserrat Regular";
  font-weight: bold;
  padding-top: 40px;
  margin-bottom: 40px;

  & .titulos {
    font-size: 20px;
  }

  & .titulo-encuesta {
    color: $color-azul;
    font-size: 22px;
    font-weight: bold;
  }

  & .nombre-conferencia {
    font-size: 18px;
    color: $color-azul;
  }

  & .MuiSwitch-root {
    width: 75px;
  }

  & .MuiFormControlLabel-label {
    font-size: 18px !important;
    color: #54555b;
  }

  & .MuiSwitch-colorPrimary.Mui-checked {
    background: #7EA8AD !important;
    color: #A3D783 !important;
  }

  & .MuiSwitch-colorPrimary {
    background: #7EA8AD !important;
    color: #A3D783 !important;
  }

  & .MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
    background-color: $color-azul !important;
  }

  & .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(35px) !important;
  }
}