@import '../../resources/scss/variables';

@font-face {
	font-family: 'Poppins Regular';
	src: url('../../resources/fonts/Poppins-Regular.ttf');
}

@font-face {
	font-family: 'Roboto Regular';
	src: url('../../resources/fonts/Roboto-Regular.ttf');
}

#podcasts {
	margin-top: 60px;
	margin-bottom: 60px;
	font-size: 18px;
	color: $color-azul !important;

	& .abner {
		position: relative;
		& .cuadro {
			width: 100%;
			height: 320px;
			// background-image: url(../../resources/images/streamingOnLine/cuadro-emocional.svg);
			background-size: cover;
			background-position: center;
			position: absolute;
			top: 10%;
		}
	}

	& iframe {
		border: none;
	}

	& .title {
		font-family: 'Poppins Regular';
		font-size: 36px;
		font-weight: bold;

		& span {
			background: linear-gradient(89.86deg, #fff 0.12%, #08041c 98.29%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
		}
	}

	& .descripcion-salas {
		font-family: 'Roboto Regular';
		font-size: 18px;
	}

	& .description {
		// font-size: 14px;
		font-family: 'Roboto Regular';
		color: $color-hover;
	}
	& #section-two {
		margin-top: 30px;
		margin-bottom: 30px;
		background-color: $color-azul;
		padding: 25px 0;

		& p {
			color: #fff !important;
		}

		.btn {
			line-height: 50px !important;
			height: 50px !important;
			text-align: center !important;
			width: 250px !important;
			cursor: pointer !important;
		}

		.btn-one {
			color: #fff !important;
			transition: all 0.3s !important;
			position: relative !important;
		}

		.btn-one span {
			transition: all 0.3s !important;
		}

		.btn-one::before {
			content: '' !important;
			position: absolute !important;
			bottom: 0 !important;
			left: 0 !important;
			width: 100% !important;
			height: 100% !important;
			z-index: 1 !important;
			opacity: 0 !important;
			transition: all 0.3s !important;
			border-top-width: 1px !important;
			border-bottom-width: 1px !important;
			border-top-style: solid !important;
			border-bottom-style: solid !important;
			border-top-color: #fff !important;
			border-bottom-color: #fff !important;
			transform: scale(0.1, 1) !important;
		}

		.btn-one:hover span {
			letter-spacing: 2px !important;
		}

		.btn-one:hover::before {
			opacity: 1 !important;
			transform: scale(1, 1) !important;
		}

		.btn-one::after {
			content: '' !important;
			position: absolute !important;
			bottom: 0 !important;
			left: 0 !important;
			width: 100% !important;
			height: 100% !important;
			z-index: 1 !important;
			transition: all 0.3s !important;
			background-color: rgba(255, 255, 255, 0.1) !important;
		}

		.btn-one:hover::after {
			opacity: 0 !important;
			transform: scale(0.1, 1) !important;
		}
	}

	& .name-conferencista {
		display: inline-flex;
		font-family: 'Roboto Regular';
		margin-bottom: 30px;

		& .img-conferencista {
			width: 155px;
			height: 145px;
			background-repeat: no-repeat;
			background-size: cover;
			margin-right: 40px;
		}

		& .job-conferencista {
			font-weight: normal;
			font-family: 'Roboto Regular';
			font-size: 18px;

			& .name {
				// background: linear-gradient(89.86deg, #fff 0.12%, #08041c 98.29%);
				// -webkit-background-clip: text;
				// -webkit-text-fill-color: transparent;
				// background-clip: text;
				// text-fill-color: transparent;
				color: $color-azul;
				font-size: 23px;
				font-weight: bold;
			}

			& .job {
				// background: linear-gradient(89.86deg, #fff 0.12%, #08041c 98.29%);
				// -webkit-background-clip: text;
				// -webkit-text-fill-color: transparent;
				// background-clip: text;
				// text-fill-color: transparent;
				color: $color-azul;
				font-size: 16px;
				font-weight: bold;
			}
		}

		& .description-conferencista {
			color: $color-azul !important;
			font-family: 'Roboto Regular';
			font-size: 18px;
			text-align: left;
		}
	}

	& .container-sala {
		position: relative;
		border-radius: 15px;

		box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.61);
		font-family: 'Roboto Regular';
		padding-bottom: 20px;

		&:hover {
			-webkit-box-shadow: 1px 7px 20px 8px rgba(0, 0, 0, 0.35);
			box-shadow: 1px 7px 20px 8px rgba(0, 0, 0, 0.35);
			// transform: scale(1.1);
		}

		& img {
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
		}

		& .pais {
			color: $color-normal;
			font-size: 22px;
			font-weight: bold;
			padding-top: 15px;

			padding-bottom: 20px;
		}

		& .btn-sala {
			background-color: #000;
			color: $color-azul;
			width: 120px;
			padding: 10px;
			text-align: center;
			margin: auto;
			cursor: pointer;

			&:hover {
				background-color: $color-normal;
				color: #000;
			}
		}
	}
}

@media screen and (max-width: 768px) and (orientation: portrait) {
	#podcasts {
		& .abner {
			& .cuadro {
				width: 100%;
				height: 120px;
				top: 20%;
			}
			// & .cuadro {
			// 	display: none;
			// }

			& .name-conferencista {
				display: block;
				text-align: center;
			}
		}

		.contenedor-encuesta {
			& .titulo-encuesta {
				font-size: 15px;
			}

			& .pregunta-encuesta {
				font-size: 14px;
			}
		}
	}
}

@media screen and (max-width: 1180px) and (orientation: landscape) {
	#podcasts {
		& .abner {
			& .cuadro {
				width: 100%;
				height: 220px;
				top: 20%;
			}
		}

		& .container-sala {
			& .btn-sala {
				width: 80px;
			}
		}
	}
}
